.App {
    background-color: #ffffff;
    font-size: calc(10px + 2vmin);
    color: white;
}

.heading {
    width: fit-content;
    margin-left: 2%;
}

.mnHeading {
    text-align: center;
    font-size: 6rem;
}

.headingDescription {
    margin-top: .5rem;
}

.headingContainer {
    width: 100%;
    height: 12rem;
    padding: 1rem;
    margin-top: 13rem;
}
.gridItem {
    background-color: red;
}
.gridItem:hover {
    background-color: gray;
}
.skillRow {
    border-bottom: '1px solid black';
}
.skillRow:hover {
    border-bottom: '1px solid red'
}